<div class="number-picker" [class.has-error]="control().errors && (control().touched || isParentStepInteracted())">
	<div class="number-picker-group">
		<button
			class="decrease"
			(click)="decrease()"
			[disabled]="control().disabled || control().value <= 1"
			role="spinbutton"
		>
			<cramo-icon iconName="remove" [size]="18" />
		</button>

		<input
			type="number"
			inputmode="numeric"
			[formControl]="control()"
			(keypress)="preventForbiddenCharacters($event)"
			[class]="{ invalid: !control().valid && !control().disabled }"
		/>

		<button class="increase" (click)="increase()" [disabled]="control().disabled" role="spinbutton">
			<cramo-icon iconName="add" [size]="18" />
		</button>
	</div>

	@let errorMessage = control().errors | errorMessage: errorMessageMap();

	@if (control().errors && (control().touched || isParentStepInteracted()) && errorMessage) {
		<mat-error>
			{{ errorMessage }}
		</mat-error>
	}
</div>
