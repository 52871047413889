@let cartOrderRow = orderRow();

<h5 mat-dialog-title [textContent]="appData().cart.successModalTitle"></h5>

<mat-dialog-content class="content">
	<p>{{ appData().cart.successModalPreamble }}</p>

	<div class="cart-product-info-container" [ngClass]="{ loading: !cartOrderRow }">
		@if (cartOrderRow) {
			<img cramoFadeInImageOnLoad [src]="cartOrderRow.imageUrl + '?width=160'" alt="" class="product-image" />
		} @else {
			<div class="product-image"></div>
		}
		<div class="info">
			<p class="name">{{ cartOrderRow?.name }}&ZeroWidthSpace;</p>
			<p class="product-name">{{ cartOrderRow?.brandAndType }}&ZeroWidthSpace;</p>

			@if (cartOrderRow) {
				<cramo-cart-order-row-quantity-picker [control]="formControl" />
			} @else {
				<div class="quantity-picker-skeleton"></div>
			}
		</div>
	</div>

	<div class="buttons">
		<cramo-button [text]="appData().cart.successModalShopMoreButtonText" buttonStyle="stroked" (onclick)="close()" />
		<cramo-button
			[text]="appData().cart.successModalCartButtonText"
			buttonColor="primary"
			prefixIcon="shopping_cart"
			routerLink="/cart"
			(onclick)="close()"
			[isLoading]="this.isLoadingQuantity()"
		/>
	</div>
</mat-dialog-content>

<cramo-button mat-dialog-close prefixIcon="close" class="close-dialog" />
