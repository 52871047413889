import { Directive, ElementRef, Renderer2 } from '@angular/core';

@Directive({
	selector: 'img[cramoFadeInImageOnLoad]',
	standalone: true,
})
export class FadeInImageOnLoadDirective {
	constructor(
		private el: ElementRef,
		private renderer: Renderer2,
	) {
		const element = this.el.nativeElement as HTMLElement;

		if (element.tagName.toLowerCase() !== 'img') {
			console.warn('cramoFadeInImageOnLoad directive is intended to be used on img tags only.');
			return;
		}

		this.renderer.setStyle(element, 'opacity', '0');
		this.renderer.setStyle(element, 'transition', 'opacity 0.3s ease');

		this.renderer.listen(element, 'load', () => {
			this.renderer.setStyle(element, 'opacity', '1');
		});
	}
}
