import { Component, inject, input, OnInit } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { FormControl, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatError } from '@angular/material/form-field';
import { ErrorMessageMap, ErrorMessagePipe } from '../../pipes/error-message.pipe';
import { EpiService } from '../../services/epi/epi.service';
import { MatStepInteractionBase } from '../../utils/mat-step-interaction-base';
import { IconComponent } from '../icon/icon.component';

@Component({
	selector: 'cramo-number-picker',
	standalone: true,
	imports: [MatButtonModule, FormsModule, ReactiveFormsModule, MatError, ErrorMessagePipe, IconComponent],
	templateUrl: './number-picker.component.html',
	styleUrls: ['./number-picker.component.scss'],
})
export class NumberPickerComponent extends MatStepInteractionBase implements OnInit {
	private epiService = inject(EpiService);
	protected appData = toSignal(this.epiService.appData$, { requireSync: true });

	public control = input.required<FormControl<number>>();
	public errorMessageMap = input<ErrorMessageMap>({});
	public min = input<number>(1);

	ngOnInit(): void {
		this.control().addValidators(Validators.min(this.min()));
	}

	protected increase(): void {
		this.control().markAsTouched();
		this.control().setValue(this.control().value + 1);
	}

	protected decrease(): void {
		this.control().markAsTouched();
		this.control().setValue(this.control().value - 1);
	}

	protected preventForbiddenCharacters(event: KeyboardEvent): void {
		const forbiddenKeys = ['.', ',', '-', 'e', ' ', '+'];

		if (forbiddenKeys.includes(event.key)) {
			return event.preventDefault();
		}
	}
}
